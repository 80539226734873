import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import styles from './SpottyCatIntro.module.scss';

const WAITING_TIME = 3000;

const SpottyCatIntro = ({ onNextStep }) => {
  useEffect(() => {
    setTimeout(onNextStep, WAITING_TIME);
  }, [onNextStep]);

  return (
    <div className={styles['spotty-cat-intro']}>
      <div>
        <img
          src="https://static.spotahome.com/images/logo-loading.gif"
          alt={trans('spotty-cat-first-screen-text')}
        />
        <p className={styles['spotty-cat-intro__title']}>
          {trans('spotty-cat-first-screen-text')}
        </p>
      </div>
    </div>
  );
};

SpottyCatIntro.propTypes = {
  onNextStep: PropTypes.func.isRequired
};

export default SpottyCatIntro;
