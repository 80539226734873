export const ENTER_KEY = 13;
export const ARROW_UP = 38;
export const ARROW_DOWN = 40;
export const SHADOW_OFFSET = 4;

export default {
  ENTER_KEY,
  ARROW_UP,
  ARROW_DOWN,
  SHADOW_OFFSET
};
