import PropTypes from 'prop-types';

const CityMarkerIcon = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="34"
    viewBox="0 0 30 34"
    className={className}
  >
    <defs>
      <filter
        id="j3zv80xtha"
        width="107.6%"
        height="114.9%"
        x="-3.8%"
        y="-7.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.122896635 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g stroke="#003762">
        <g>
          <g
            filter="url(#j3zv80xtha)"
            transform="translate(-23 -253) translate(0 -246) translate(27.65 459)"
          >
            <g>
              <g transform="translate(4.35 49)">
                <ellipse cx="5.812" cy="5.538" rx="1.947" ry="1.962" />
                <path d="M5.813.5c1.47.002 2.8.575 3.762 1.502.956.921 1.549 2.192 1.55 3.596 0 1.875-1.82 4.679-5.312 8.439C2.32 10.277.5 7.473.5 5.597c0-1.404.594-2.675 1.55-3.595C3.014 1.074 4.345.502 5.813.5z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

CityMarkerIcon.propTypes = {
  className: PropTypes.string
};

export default CityMarkerIcon;
