import PropTypes from 'prop-types';
import classNames from 'classnames';

import CityMarkerIcon from '../icons/CityMarkerIcon';

import AutocompleterItemMatch from './AutocompleterItemMatch';

import styles from './Autocompleter.module.scss';

const escapeValue = value => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

const AutocompleterItem = ({
  noResult = false,
  selected = false,
  keyword = '',
  item,
  onClick = () => {}
}) => {
  const handleClick = () => {
    onClick(item);
  };

  const itemClasses = classNames(styles.autocompleter__item, {
    [styles['autocompleter__item--preselected']]: selected,
    [styles['autocompleter__item--no-result']]: noResult
  });

  const itemInnerClasses = classNames(styles['autocompleter__item-inner'], {});

  const renderSplitedItem = () => {
    const regExp = new RegExp(escapeValue(keyword), 'gi');
    const matches = [];
    let match;
    // eslint-disable-next-line no-cond-assign
    while ((match = regExp.exec(item))) {
      matches.push(match);
    }

    // Split by the keyword regex to get the gaps where we will insert
    const splittedGaps = item.split(regExp);

    // the match is the whole item
    if (!splittedGaps.length && matches.length) {
      return <AutocompleterItemMatch keyword={matches[0][0]} />;
    }

    // The item is displayed, but it doesn't match with the keyword (user navigating with arrows)
    if (splittedGaps.length && !matches.length) {
      return splittedGaps;
    }

    return splittedGaps.reduce((acc, cur, i) => [
      ...acc,
      <AutocompleterItemMatch
        key={`${item}_match_${i - 1}`}
        keyword={matches[i - 1][0]}
      />,
      cur
    ]);
  };

  return (
    <div
      className={itemClasses}
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <span className={itemInnerClasses}>
        {noResult ? null : <CityMarkerIcon />}
        {keyword ? renderSplitedItem() : item}
      </span>
    </div>
  );
};

AutocompleterItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  keyword: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  noResult: PropTypes.bool
};

export default AutocompleterItem;
