import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import FormRadioButton from '../../FormRadioButton';

import IconExperiment from '../icons/IconExperiment';
import IconBow from '../icons/IconBow';
import IconHeartLock from '../icons/IconHeartLock';

import styles from '../SpottyCat.module.scss';

const RentalType = ({ rentalType, onRentalTypeSelected }) => (
  <>
    <p className={styles['spotty-cat__title']}>
      {trans('spotty-cat-screen-title-rental-type')}
    </p>
    <div className={styles['spotty-cat__options']}>
      <FormRadioButton
        value="flirting"
        customIcon={() => <IconExperiment />}
        selected={rentalType === 'flirting'}
        onClick={() => onRentalTypeSelected('flirting')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-movein-flirt-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-movein-flirt-description')}
          </p>
        </div>
      </FormRadioButton>

      <FormRadioButton
        value="romance"
        customIcon={() => <IconBow />}
        selected={rentalType === 'romance'}
        onClick={() => onRentalTypeSelected('romance')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-movein-romance-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-movein-romance-description')}
          </p>
        </div>
      </FormRadioButton>
      <FormRadioButton
        value="trueLove"
        customIcon={() => <IconHeartLock />}
        selected={rentalType === 'trueLove'}
        onClick={() => onRentalTypeSelected('trueLove')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-movein-true-love-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-movein-true-love-description')}
          </p>
        </div>
      </FormRadioButton>
    </div>
  </>
);

RentalType.propTypes = {
  rentalType: PropTypes.string.isRequired,
  onRentalTypeSelected: PropTypes.func.isRequired
};
export default RentalType;
