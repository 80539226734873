import PropTypes from 'prop-types';

import Colors from '../utils/colors';

const MagnifyingGlass = ({ color, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-34 -160) translate(0 -246)">
        <path
          fill={color}
          fillRule="nonzero"
          stroke={color}
          strokeWidth="1.2"
          d="M5.548 10.452c2.708 0 4.904-2.196 4.904-4.904 0-2.709-2.196-4.905-4.904-4.905C2.839.643.643 2.84.643 5.548c0 2.708 2.196 4.904 4.905 4.904zM9.69 9.237l6.215 6.214c.125.126.125.33 0 .455-.126.125-.33.125-.455 0L9.237 9.69c-.98.874-2.273 1.404-3.69 1.404C2.485 11.095 0 8.612 0 5.548 0 2.484 2.484 0 5.548 0s5.547 2.484 5.547 5.548c0 1.416-.53 2.708-1.404 3.689z"
          transform="translate(35 407.25)"
        />
      </g>
    </g>
  </svg>
);

MagnifyingGlass.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

MagnifyingGlass.defaultProps = {
  className: '',
  color: Colors.primary
};

export default MagnifyingGlass;
