const IconBow = () => (
  <svg
    height="32px"
    width="32px"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    enableBackground="new 0 0 64 64"
    xmlSpace="preserve"
  >
    <path d="M17.474,12.854c-1.987,0-4-1.342-4-3.902c0-2.563,2.013-3.902,4-3.902s4,1.34,4,3.902  C21.474,11.512,19.461,12.854,17.474,12.854z M17.474,7.049c-0.923,0-2,0.498-2,1.902c0,1.402,1.077,1.902,2,1.902s2-0.5,2-1.902  C19.474,7.547,18.396,7.049,17.474,7.049z" />
    <path d="M55,50.381c-1.987,0-4-1.342-4-3.902c0-2.563,2.013-3.902,4-3.902s4,1.34,4,3.902C59,49.039,56.987,50.381,55,50.381z   M55,44.576c-0.923,0-2,0.498-2,1.902c0,1.402,1.077,1.902,2,1.902s2-0.5,2-1.902C57,45.074,55.923,44.576,55,44.576z" />
    <path d="M37.958,26.992c-0.536,0-0.98-0.426-0.998-0.967l-0.119-3.578c-0.146-4.309-3.342-7.922-7.602-8.59l-1.892-0.291  c-1.247-0.189-2.494-0.379-3.737-0.592c-0.544-0.094-0.909-0.611-0.815-1.156c0.094-0.543,0.611-0.908,1.155-0.814  c1.229,0.211,2.464,0.398,3.697,0.586l1.902,0.291c5.206,0.818,9.112,5.234,9.289,10.5l0.119,3.578  c0.019,0.551-0.414,1.014-0.966,1.031C37.98,26.992,37.97,26.992,37.958,26.992z" />
    <path d="M51.962,41.172c-0.48,0-0.904-0.348-0.985-0.836c-0.208-1.246-0.4-2.496-0.592-3.746l-0.292-1.879  c-0.667-4.26-4.28-7.455-8.59-7.6l-3.577-0.121c-0.553-0.018-0.984-0.48-0.966-1.031c0.018-0.553,0.522-1.008,1.032-0.967  l3.577,0.121c5.268,0.176,9.684,4.082,10.5,9.287l0.292,1.887c0.19,1.24,0.382,2.48,0.588,3.721c0.091,0.545-0.277,1.059-0.822,1.15  C52.071,41.168,52.017,41.172,51.962,41.172z" />
    <path d="M34.086,30.863c-0.536,0-0.98-0.426-0.998-0.967l-0.245-7.314c-0.081-2.395-1.856-4.4-4.223-4.771l-5.528-0.867  c-2.896-0.455-5.514-2.102-7.179-4.518c-0.313-0.453-0.199-1.076,0.256-1.391c0.455-0.313,1.077-0.197,1.391,0.256  c1.355,1.967,3.484,3.307,5.843,3.676l5.528,0.867c3.313,0.52,5.798,3.33,5.91,6.682l0.245,7.314  c0.019,0.553-0.413,1.014-0.966,1.033C34.109,30.863,34.098,30.863,34.086,30.863z" />
    <path d="M52.091,48.215c-0.195,0-0.394-0.059-0.566-0.178c-2.415-1.664-4.061-4.281-4.517-7.178l-0.866-5.527  c-0.371-2.367-2.378-4.143-4.772-4.225l-7.315-0.244c-0.553-0.02-0.984-0.48-0.966-1.033c0.018-0.541,0.462-0.967,0.998-0.967  c0.012,0,0.023,0,0.034,0.002l7.315,0.244c3.353,0.113,6.162,2.6,6.683,5.912l0.866,5.527c0.37,2.357,1.71,4.488,3.675,5.842  c0.455,0.314,0.569,0.938,0.256,1.391C52.721,48.063,52.408,48.215,52.091,48.215z" />
    <path d="M12.887,46.408c-0.014,0-0.026,0-0.039,0l-2.065-0.08c-0.552-0.02-0.982-0.484-0.961-1.037  c0.021-0.539,0.464-0.961,0.998-0.961c0.014,0,0.026,0,0.039,0l2.065,0.08c0.552,0.02,0.982,0.484,0.961,1.037  C13.864,45.986,13.421,46.408,12.887,46.408z" />
    <path d="M6,51.15c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l4.82-4.82c0.391-0.391,1.023-0.391,1.414,0  s0.391,1.023,0,1.414l-4.82,4.82C6.512,51.053,6.256,51.15,6,51.15z" />
    <path d="M13.516,51.436c-0.013,0-0.026,0-0.039,0l-7.515-0.287c-0.552-0.02-0.982-0.484-0.961-1.037  c0.021-0.551,0.493-1.004,1.037-0.961l7.515,0.287c0.552,0.02,0.982,0.484,0.961,1.037C14.493,51.014,14.05,51.436,13.516,51.436z" />
    <path d="M18.62,54.131c-0.534,0-0.978-0.422-0.998-0.961l-0.079-2.066c-0.021-0.551,0.409-1.016,0.961-1.037  c0.505-0.018,1.017,0.408,1.037,0.961l0.079,2.066c0.021,0.551-0.409,1.016-0.961,1.037C18.646,54.131,18.634,54.131,18.62,54.131z" />
    <path d="M13.801,58.951c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l4.82-4.82  c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-4.82,4.82C14.313,58.854,14.057,58.951,13.801,58.951z" />
    <path d="M13.8,58.951c-0.534,0-0.978-0.424-0.998-0.963l-0.286-7.514c-0.021-0.553,0.409-1.018,0.961-1.037  c0.536-0.037,1.016,0.408,1.037,0.961l0.286,7.514c0.021,0.553-0.409,1.018-0.961,1.037C13.826,58.951,13.813,58.951,13.8,58.951z" />
    <path d="M46.021,11.127c-0.476,0-0.896-0.34-0.983-0.822C44.94,9.76,45.302,9.24,45.846,9.143l9.508-1.705  c0.544-0.096,1.063,0.264,1.161,0.807c0.098,0.545-0.264,1.064-0.808,1.162l-9.508,1.705C46.14,11.123,46.08,11.127,46.021,11.127z" />
    <path d="M53.824,18.928c-0.059,0-0.118-0.004-0.178-0.016c-0.544-0.098-0.905-0.617-0.808-1.16l1.707-9.508  c0.098-0.543,0.617-0.904,1.161-0.807s0.905,0.617,0.808,1.16l-1.707,9.508C54.721,18.588,54.299,18.928,53.824,18.928z" />
    <path d="M13.515,51.436c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414L49.216,13.32  c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L14.222,51.143C14.026,51.338,13.771,51.436,13.515,51.436z" />
    <path d="M53.823,18.928c-0.256,0-0.512-0.098-0.707-0.293l-7.801-7.801c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0  l7.801,7.801c0.391,0.391,0.391,1.023,0,1.414C54.335,18.83,54.079,18.928,53.823,18.928z" />
    <path d="M49.901,48.256c-4.474,0-8.938-0.516-13.302-1.537c-2.535-0.594-5.051-1.363-7.477-2.289  c-2.265-0.863-4.444-1.791-6.22-3.615c-1.719-1.768-2.608-3.916-3.514-6.336c-0.912-2.439-1.666-4.959-2.241-7.49  c-1.132-4.971-1.606-10.074-1.411-15.168c0.021-0.553,0.532-0.982,1.037-0.961c0.552,0.021,0.982,0.486,0.961,1.037  c-0.188,4.918,0.271,9.848,1.362,14.648c0.556,2.443,1.284,4.877,2.165,7.232c0.854,2.285,1.624,4.15,3.074,5.643  c1.491,1.533,3.452,2.359,5.499,3.141c2.343,0.895,4.771,1.639,7.22,2.211c4.914,1.15,9.961,1.635,14.998,1.443  c0.511-0.041,1.017,0.408,1.037,0.961c0.021,0.553-0.409,1.018-0.961,1.037C51.388,48.242,50.645,48.256,49.901,48.256z" />
  </svg>
);

export default IconBow;
