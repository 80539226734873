const normalizeListValue = locality =>
  locality
    ? locality
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .replace(new RegExp(/ł/g), 'l')
    : '';

export default normalizeListValue;
