/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getPagePath, trans } from '@spotahome/soyuz/client';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import Button from '../../Button';

import IconConfetti from '../icons/IconConfetti';
import IconWhatsapp from '../icons/IconWhatsapp';
import { isMobile } from '../utils';

import styles from '../SpottyCat.module.scss';

const WHATSAPP_URL = 'https://wa.me/+34911983151';

const Success = ({ email, searchUrl, isContactUsShown }) => {
  useEffect(() => {
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'FormSuccess',
      value: 'Arrival'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCTASubmit = () => {
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'FormSuccess',
      value: 'Click-CTA'
    });
  };

  const handleWhatsappClick = () => {
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'FormSuccess',
      value: 'Click-WhatsApp'
    });
  };

  const handleContactUsClick = () => {
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      action: 'FormSuccess',
      value: 'Click-ContactUs'
    });
  };

  return (
    <div className={styles['spotty-cat__success']}>
      <div className={styles['spotty-cat__success-icon']}>
        <IconConfetti />
      </div>

      <span className={styles['spotty-cat__success-title']}>
        {trans('spotty-cat-screen-title-feedback')}
      </span>

      <div>
        <p>{trans('spotty-cat-screen-title-feedback-description')}</p>
        <p className={styles['spotty-cat__success-highlighted']}>{email}</p>
        <Button
          size="medium"
          className={styles['spotty-cat__button']}
          onClick={handleCTASubmit}
          href={searchUrl}
        >
          {trans('spotty-cat-screen-feedback-cta')}
        </Button>
      </div>

      {isMobile() && (
        <div className={styles['spotty-cat__success-whatsapp']}>
          <p className={styles['spotty-cat__success-highlighted']}>
            {trans('spotty-cat.whatsapp.title')}
          </p>
          <p>{trans('spotty-cat.whatsapp.subtitle')}</p>
          <Button
            className={styles['spotty-cat__button--whatsapp']}
            href={WHATSAPP_URL}
            target="_blank"
            color="tertiary"
            size="medium"
            renderIcon={() => <IconWhatsapp />}
            onClick={handleWhatsappClick}
          >
            {trans('spotty-cat.whatsapp.cta')}
          </Button>
        </div>
      )}

      {isContactUsShown && (
        <div className={styles['spotty-cat__success-whatsapp']}>
          <p className={styles['spotty-cat__success-highlighted']}>
            {trans('spotty-cat.contact-us.title')}
          </p>
          <p>{trans('spotty-cat.contact-us.subtitle')}</p>
          <Button
            className={styles['spotty-cat__button--whatsapp']}
            href={getPagePath('contact-us')}
            target="_blank"
            color="tertiary"
            size="small"
            onClick={handleContactUsClick}
          >
            {trans('spotty-cat.contact-us.cta')}
          </Button>
        </div>
      )}
    </div>
  );
};

Success.propTypes = {
  email: PropTypes.string.isRequired,
  searchUrl: PropTypes.string.isRequired,
  isContactUsShown: PropTypes.bool.isRequired
};

export default Success;
