const IconLovers = () => (
  <svg
    height="32px"
    width="32px"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    x="0px"
    y="0px"
  >
    <g>
      <path d="M48.841,43.445l-4.609-9.217a3,3,0,0,0,.055-2.779,2.961,2.961,0,0,0-1.209-1.271,3.028,3.028,0,0,0-.633-.261l-1.288-2.493a7.99,7.99,0,0,0-13.631-1.11A7.994,7.994,0,0,0,12,29v2.788A2.964,2.964,0,0,0,11,34a3,3,0,0,0,3,3h1.1a4.953,4.953,0,0,0,.9,1.969V43.1A13.56,13.56,0,0,0,4,56.544V61a1,1,0,0,0,1,1H58a1,1,0,0,0,1-1V56.544A13.558,13.558,0,0,0,48.841,43.445Zm-2.435-.4c-.315-.022-.629-.048-.95-.048h-.983l-2.435-4.871a4.964,4.964,0,0,0-.077-2.163l.723-.361ZM30,41.022a2.97,2.97,0,0,0,2.213-.181l.985-.493a4.917,4.917,0,0,0,1.684,1.36l1.054,2.108a13.391,13.391,0,0,0-4.442,2.669A13.761,13.761,0,0,0,30,45.3ZM29.977,38.6a1,1,0,0,1,.447-1.341l.894-.448.895,1.789-.894.448A1,1,0,0,1,29.977,38.6Zm4.472,0-1.467-2.933,3.637-3.637,1.3.1,1.893,3.787A3,3,0,0,1,34.449,38.6Zm4.919,3.13a4.95,4.95,0,0,0,1.6-1.273L42.236,43H40.544a13.762,13.762,0,0,0-2.649.26l-.505-1.01A4.973,4.973,0,0,0,39.368,41.734Zm2.3-9.942a1.028,1.028,0,0,1,.426.128.99.99,0,0,1,.4.423,1,1,0,0,1-.446,1.342l-.9.447-.3-.6-.57-1.138ZM31.318,25.635a6.01,6.01,0,0,1,8.056,2.695l.962,1.862-.261.113v-.011l-3.763-.289a1.019,1.019,0,0,0-.783.29l-4.356,4.356-1.645.823h0l-.894-1.789A6.006,6.006,0,0,1,31.318,25.635Zm-3.56,10.77.218.435L28,36.828v.487a2.972,2.972,0,0,0,0,1.7V44.2A13.415,13.415,0,0,0,24,43.1V38.969A4.953,4.953,0,0,0,24.9,37H26A2.981,2.981,0,0,0,27.758,36.405Zm-.918-1.839-.011-.007A1,1,0,0,1,26,35H25V33.387l1.55.517C26.637,34.126,26.731,34.348,26.84,34.566ZM15,35H14a1,1,0,0,1-1-1v0a1,1,0,0,0,.515-.141L15,32.966Zm-1-3.767V29a6,6,0,0,1,12,0v2.612l-7.684-2.56A.983.983,0,0,0,18,29a1,1,0,0,0-.515.143ZM20,39a3,3,0,0,1-3-3V31.766l1.12-.672L23,32.72V36A3,3,0,0,1,20,39Zm2,1.576V43H18V40.576a4.929,4.929,0,0,0,4,0ZM34,60H29V55H27v5H12V55H10v5H6V56.544A11.558,11.558,0,0,1,17.544,45h4.912A11.558,11.558,0,0,1,34,56.544Zm23,0H53V55H51v5H36V56.544a13.481,13.481,0,0,0-3.112-8.627A11.425,11.425,0,0,1,40.544,45h4.912A11.558,11.558,0,0,1,57,56.544Z" />
      <path d="M31.939,7.063a3.911,3.911,0,0,0-3.067,1.475L27.959,9.68l-.913-1.141a3.926,3.926,0,0,0-6.579.693l-.135.27a3.946,3.946,0,0,0,.859,4.651l6.092,5.584a1,1,0,0,0,1.352,0l6.092-5.584h0A3.946,3.946,0,0,0,35.585,9.5l-.133-.266A3.907,3.907,0,0,0,31.939,7.063Zm1.436,5.616-5.416,4.965-5.416-4.965A1.935,1.935,0,0,1,22.12,10.4l.135-.269a1.925,1.925,0,0,1,3.228-.341l1.7,2.117a1.032,1.032,0,0,0,1.56,0l1.7-2.118a1.925,1.925,0,0,1,3.228.343l.133.266A1.936,1.936,0,0,1,33.375,12.679Z" />
      <rect x="43" y="4" width="2" height="2" />
      <rect x="43" y="10" width="2" height="2" />
      <rect x="40" y="7" width="2" height="2" />
      <rect x="46" y="7" width="2" height="2" />
      <rect x="8" y="12" width="2" height="2" />
      <rect x="8" y="20" width="2" height="2" />
      <rect x="4" y="16" width="2" height="2" />
      <rect x="12" y="16" width="2" height="2" />
    </g>
  </svg>
);

export default IconLovers;
