import PropTypes from 'prop-types';

import styles from './SpottyCatProgressBar.module.scss';

const SpottyCatProgressBar = ({ step, totalSteps }) => {
  const progress = parseInt((step / totalSteps) * 100, 10);
  return (
    <div className={styles.spottyCatBar} style={{ width: `${progress}%` }}>
      <p className={styles.spottyCatBar__progress}>{`${progress}%`}</p>
    </div>
  );
};
SpottyCatProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired
};

export default SpottyCatProgressBar;
