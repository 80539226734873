import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Fuse from 'fuse.js';

import MagnifyingGlass from '../icons/MagnifyingGlass';
import debounce from '../utils/debounce';
import { Input } from '../Input/Input';

import ModalHeader from '../ModalHeader';

import AutocompleterNoResult from './AutocompleterNoResult';
import AutocompleterItem from './AutocompleterItem';

import handlers from './navigationHandlers';
import { ENTER_KEY } from './constants';

import normalizeListValue from './utils';
import styles from './Autocompleter.module.scss';

// for a broader filtering to support ES searches without tildes (i.e: cadiz to show Cádiz)
const FILTER_THRESHOLD = 0.7;
// show results that make sense, not just return any option due to a lenient searching
const DISPLAY_THRESHOLD = 0.4;

// https://fusejs.io/api/options.html
const FUSE_OPTIONS = {
  threshold: FILTER_THRESHOLD,
  includeScore: true
};

const normalizeList = list =>
  list.reduce(
    (acc, cityName) => ({
      ...acc,
      [normalizeListValue(cityName)]: cityName
    }),
    {}
  );

const getInitialState = ({
  value = '',
  showFullscreenModal = false,
  list = []
}) => ({
  value,
  height: '0px',
  selectedIndex: -1,
  filtered: [],
  mustRender: false,
  showTypingHint: false,
  showFeaturedResults: false,
  showFullscreenModal,
  normalizedList: normalizeList(list)
});

const generatePartialStateBasedOnFilter = (
  keyword,
  currentIndex,
  normalizedCityNames
) => {
  const mustRender = true;
  const fuse = new Fuse(Object.keys(normalizedCityNames), FUSE_OPTIONS);
  const fuseSuggestions = fuse.search(keyword);
  const nResults = fuseSuggestions.length;
  let selectedIndex = -1;

  if (nResults) {
    selectedIndex = currentIndex >= nResults ? nResults - 1 : currentIndex;
  }

  const suggestions = fuseSuggestions.filter(
    item => item.score < DISPLAY_THRESHOLD
  );
  const recommendedItemsBasedOnSearch = suggestions.map(obj => obj.item);

  return {
    filtered: recommendedItemsBasedOnSearch,
    mustRender,
    selectedIndex
  };
};

const mustFilter = (keyword, minLength) => keyword.length >= minLength;

class Autocompleter extends PureComponent {
  inputRef = createRef();

  state = getInitialState({
    value: this.props.initialValue,
    showMobileFullscreen: this.props.showMobileFullscreen,
    list: this.props.list
  });

  callOnNoResults = debounce(() => {
    this.props.onNoResults(this.state.value);
  }, 250);

  componentDidMount = () => {
    document.addEventListener('click', this.closeAutocompleter);
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    let nextState = null;
    if (nextProps.initialValue !== this.state.value) {
      nextState = {
        ...nextState,
        value: nextProps.initialValue
      };
    }

    if (JSON.stringify(nextProps.list) !== JSON.stringify(this.props.list)) {
      nextState = {
        ...nextState,
        normalizedList: normalizeList(nextProps.list)
      };
    }

    if (nextState) {
      this.setState(nextState);
    }
  };

  componentDidUpdate = () => {
    const finalHeight = this.listContainer.children.length
      ? `${this.listContainer.offsetHeight}px`
      : '0px';

    requestAnimationFrame(() => {
      this.setState({ height: finalHeight });
    });
  };

  componentWillUnmount = () => {
    document.removeEventListener('click', this.closeAutocompleter);
  };

  handleChange = e => {
    const { value } = e.target;

    const partialState = this.generatePartialState(
      normalizeListValue(value),
      this.state.selectedIndex
    );

    this.setState(
      {
        value,
        showFeaturedResults: false,
        showTypingHint: false,
        ...partialState
      },
      () => {
        this.props.onChange(value);
      }
    );
  };

  handleOnBlur = event => {
    const { value } = event.target;
    const { name } = this.props;
    this.props.onBlur({ name, value });
  };

  handleNavigation = e => {
    if (typeof this[e.keyCode] === 'function') {
      e.preventDefault();
      this[e.keyCode]();
    } else if (typeof handlers[e.keyCode] === 'function') {
      e.preventDefault();
      const newState = handlers[e.keyCode](this.state);
      newState && this.setState(newState);
    }
  };

  handleFocus = e => {
    e.preventDefault();

    if (this.props.selectAllContentOnFocus) {
      this.inputRef.current.select();
    }
  };

  handleEndSelection = selectedValue => {
    const normalizedSelectedValue = normalizeListValue(selectedValue);
    const selectedValueDenormalized =
      this.state.normalizedList[normalizedSelectedValue];

    this.setState(
      getInitialState({
        value: selectedValue,
        showMobileFullscreen: this.props.showMobileFullscreen,
        list: this.props.list
      }),
      () => this.props.onSelectedElement(selectedValueDenormalized)
    );
  };

  handleClick = e => {
    e.preventDefault();

    if (!this.state.value && this.props.featuredResults) {
      this.setState({
        showFeaturedResults: true,
        showTypingHint: true
      });
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  handleSubmitSuggestion = () => {
    this.props.onSubmitSuggestion();
    this.props.onCloseModal();
    this.setState({
      showFullscreenModal: false,
      mustRender: false,
      showTypingHint: false
    });
  };

  [ENTER_KEY] = () => {
    if (this.state.selectedIndex >= 0) {
      this.handleEndSelection(this.state.filtered[this.state.selectedIndex]);
    }
  };

  generatePartialState = (keyword, selectedIndex) =>
    mustFilter(keyword, this.props.minLength)
      ? generatePartialStateBasedOnFilter(
          keyword,
          selectedIndex,
          this.state.normalizedList
        )
      : getInitialState({ value: keyword, list: this.props.list });

  closeAutocompleter = e => {
    if (!this.parentNode.contains(e.target)) {
      this.setState({
        mustRender: false,
        showFeaturedResults: false,
        showTypingHint: false,
        height: '0px'
      });
    }
  };

  renderList = keyword =>
    this.state.filtered.length
      ? this.renderFilteredResults(keyword)
      : this.renderNoResults();

  renderFeaturedList = featuredItems =>
    featuredItems.map(item => (
      <AutocompleterItem
        item={item}
        key={`${item}`}
        onClick={this.handleEndSelection}
      />
    ));

  renderFilteredResults = keyword => {
    const MAX_RESULTS_TO_SHOW = 5;
    return this.state.filtered.map(
      (item, i) =>
        i < MAX_RESULTS_TO_SHOW && (
          <AutocompleterItem
            item={this.state.normalizedList[item]}
            key={`${item}_${i}`}
            keyword={keyword}
            onClick={this.handleEndSelection}
            selected={this.state.selectedIndex === i}
          />
        )
    );
  };

  renderNoResults = () => {
    const { otherCitySuggestionsEnabled } = this.props;
    this.callOnNoResults();
    return (
      <AutocompleterNoResult
        value={this.state.value}
        otherCitySuggestionsEnabled={otherCitySuggestionsEnabled}
        onSubmitSuggestion={this.handleSubmitSuggestion}
      />
    );
  };

  renderOptions = () => {
    const { mustRender, value } = this.state;
    const normalisedValue = value.toLocaleLowerCase();

    return mustRender ? this.renderList(normalisedValue) : [];
  };

  render() {
    const dynamicListHeight = { height: this.state.height };

    return (
      <div
        className={classNames(
          styles.autocompleter,
          {
            [styles['autocompleter--active']]: this.state.mustRender,
            [styles['autocompleter--error']]: this.props.hasError,
            [styles['autocompleter--fullscreen']]:
              this.props.showMobileFullscreen
          },
          this.props.className
        )}
        ref={node => (this.parentNode = node)}
      >
        {this.props.showMobileFullscreen && (
          <ModalHeader
            showBack
            showClose={false}
            className="autocompleter__header"
            onRequestClose={this.props.onCloseModal}
            dataTest="autocompleter-fullscreen-header"
          />
        )}

        <div className={styles['autocompleter__input-container']}>
          {this.props.showIcon && (
            <MagnifyingGlass
              color={this.props.iconColor}
              className={styles.autocompleter__glass}
            />
          )}
          <Input
            className={styles.autocompleter__input}
            onClick={this.handleClick}
            onChange={this.handleChange}
            onBlur={this.handleOnBlur}
            onFocus={this.handleFocus}
            onKeyDown={this.handleNavigation}
            placeholder={
              this.props.typingPlaceholder && this.state.showTypingHint
                ? this.props.typingPlaceholder
                : this.props.placeholder
            }
            ref={this.inputRef}
            type="text"
            value={this.state.value}
            dataTest={this.props.dataTest}
            name={this.props.name}
          />
        </div>

        <div
          style={dynamicListHeight}
          className={classNames(styles['autocompleter__list-wrapper'], {
            [styles['autocompleter__list-wrapper--floating']]:
              this.props.floatingResults
          })}
          data-test="autocompleter-results"
        >
          <ul
            className={styles['autocompleter__item-container']}
            ref={node => (this.listContainer = node)}
            data-test={this.props.dataTest}
          >
            {this.props.featuredResults && this.state.showFeaturedResults
              ? this.renderFeaturedList(this.props.featuredResults)
              : this.renderOptions()}
          </ul>
        </div>
      </div>
    );
  }
}

Autocompleter.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  minLength: PropTypes.number,
  placeholder: PropTypes.string,
  typingPlaceholder: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onSelectedElement: PropTypes.func,
  onNoResults: PropTypes.func,
  onCloseModal: PropTypes.func,
  onSubmitSuggestion: PropTypes.func,
  initialValue: PropTypes.string,
  hasError: PropTypes.bool,
  className: PropTypes.string,
  dataTest: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  featuredResults: PropTypes.arrayOf(PropTypes.string),
  floatingResults: PropTypes.bool,
  otherCitySuggestionsEnabled: PropTypes.bool,
  showMobileFullscreen: PropTypes.bool,
  selectAllContentOnFocus: PropTypes.bool,
  showIcon: PropTypes.bool
};

Autocompleter.defaultProps = {
  list: [],
  minLength: 1,
  placeholder: '',
  typingPlaceholder: '',
  iconColor: undefined,
  onClick: () => {},
  onChange: () => {},
  onSelectedElement: () => {},
  onNoResults: () => {},
  onCloseModal: () => {},
  onSubmitSuggestion: () => {},
  initialValue: '',
  hasError: false,
  className: '',
  dataTest: '',
  name: '',
  onBlur: () => {},
  featuredResults: [],
  floatingResults: true,
  otherCitySuggestionsEnabled: false,
  showMobileFullscreen: false,
  selectAllContentOnFocus: true,
  showIcon: true
};

export default Autocompleter;
