import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FormRadioButton.module.scss';

const cx = classNames.bind(styles);

const FormRadioButton = ({
  customIcon,
  value,
  selected,
  hideDot,
  hasError,
  horizontal,
  onClick,
  disabled,
  dataTest,
  showFeedback,
  feedbackDelay,
  noBorder,
  children
}) => {
  const radioButtonRef = useRef(null);

  useEffect(() => {
    if (showFeedback) {
      radioButtonRef.current.style.setProperty(
        '--ui-option-delay',
        `${feedbackDelay}s`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRadioButtonInputClassNames = () =>
    classNames(styles['form-radio-button__input'], {
      [styles['form-radio-button__input--disabled']]: disabled
    });

  const getRadioButtonClassNames = className =>
    cx(styles[className], {
      [styles[`${className}--selected`]]: selected,
      [styles[`${className}--blinking`]]: showFeedback && selected,
      [styles[`${className}--hideDot`]]: hideDot,
      [styles[`${className}--error`]]: hasError,
      [styles[`${className}--horizontal`]]: horizontal,
      [styles[`${className}--disabled`]]: disabled,
      [styles[`${className}--customIcon`]]: customIcon,
      [styles[`${className}--noBorder`]]: noBorder
    });

  const handleClick = event => {
    const option = event.target.value;
    onClick(option);
  };

  const dotClasses = cx(
    getRadioButtonClassNames('form-radio-button__circle', value),
    { [styles['form-radio-button__circle--right']]: customIcon }
  );

  const iconClasses = cx(styles['form-radio-button__icon'], {
    [styles['form-radio-button__icon--colored']]: selected
  });

  return (
    <>
      <label
        className={getRadioButtonClassNames('form-radio-button__label')}
        htmlFor={`form-radio-button-${value}`}
        ref={radioButtonRef}
      >
        {customIcon && <div className={iconClasses}>{customIcon()}</div>}
        <input
          id={`form-radio-button-${value}`}
          disabled={disabled}
          name={value}
          className={getRadioButtonInputClassNames()}
          onClick={handleClick}
          type="radio"
          value={value}
          data-test={`${dataTest}-${value}`}
        />
        {!hideDot && <span className={dotClasses} />}
        {children}
      </label>
    </>
  );
};

FormRadioButton.propTypes = {
  hideDot: PropTypes.bool,
  disabled: PropTypes.bool,
  dataTest: PropTypes.string,
  hasError: PropTypes.bool,
  horizontal: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  value: PropTypes.string.isRequired,
  customIcon: PropTypes.node,
  showFeedback: PropTypes.bool,
  feedbackDelay: PropTypes.number,
  noBorder: PropTypes.bool
};

FormRadioButton.defaultProps = {
  disabled: false,
  selected: false,
  hideDot: false,
  hasError: false,
  horizontal: false,
  dataTest: 'radio-button',
  onClick: () => {},
  customIcon: null,
  showFeedback: false,
  feedbackDelay: 0.5, // in seconds
  noBorder: false
};

export default FormRadioButton;
