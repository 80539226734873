const IconCouch = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.48899 24.9659H20.2403C20.4044 24.9659 20.5375 24.8329 20.5375 24.6687V21.1021C20.5375 20.938 20.4044 20.8049 20.2403 20.8049H1.48899C1.32487 20.8049 1.19177 20.938 1.19177 21.1021V24.6687C1.19177 24.8329 1.32487 24.9659 1.48899 24.9659ZM19.943 24.3715H1.7862V21.3994H19.943V24.3715Z"
      fill="black"
    />
    <path
      d="M3.21283 27.3437C2.0984 27.3437 1.19177 26.4371 1.19177 25.3227V24.6688C1.19177 24.5046 1.32487 24.3716 1.48899 24.3716C1.65311 24.3716 1.7862 24.5046 1.7862 24.6688V25.3227C1.7862 26.1093 2.42622 26.7493 3.21283 26.7493C3.99944 26.7493 4.63946 26.1093 4.63946 25.3227V24.6688C4.63946 24.5046 4.77256 24.3716 4.93668 24.3716C5.1008 24.3716 5.23389 24.5046 5.23389 24.6688V25.3227C5.23389 26.4371 4.32721 27.3437 3.21283 27.3437Z"
      fill="black"
    />
    <path
      d="M18.5164 27.3437C17.402 27.3437 16.4954 26.4371 16.4954 25.3227V24.6688C16.4954 24.5046 16.6284 24.3716 16.7926 24.3716C16.9568 24.3716 17.0898 24.5046 17.0898 24.6688V25.3227C17.0898 26.1093 17.7298 26.7493 18.5164 26.7493C19.303 26.7493 19.9431 26.1093 19.9431 25.3227V24.6688C19.9431 24.5046 20.0761 24.3716 20.2403 24.3716C20.4044 24.3716 20.5375 24.5046 20.5375 24.6688V25.3227C20.5375 26.4371 19.6309 27.3437 18.5164 27.3437Z"
      fill="black"
    />
    <path
      d="M1.48909 21.3993C1.32497 21.3993 1.19188 21.2663 1.19188 21.1021V19.8835C1.19188 19.5311 1.2408 19.1837 1.33751 18.8477C0.710747 18.389 0.329956 17.6502 0.329956 16.8668C0.329956 15.5148 1.42995 14.4148 2.78198 14.4148C3.59742 14.4148 4.35721 14.8188 4.81451 15.4956C4.90641 15.6316 4.87062 15.8163 4.73462 15.9082C4.59861 16.0001 4.41386 15.9644 4.32197 15.8284C3.97535 15.3154 3.39965 15.0092 2.78198 15.0092C1.75772 15.0092 0.924385 15.8426 0.924385 16.8668C0.924385 17.5213 1.2754 18.1349 1.84046 18.468C1.96321 18.5404 2.01736 18.6898 1.96939 18.824C1.84795 19.1641 1.78631 19.5206 1.78631 19.8835V21.1021C1.78631 21.2663 1.65322 21.3993 1.48909 21.3993Z"
      fill="black"
    />
    <path
      d="M20.2403 21.3993C20.0761 21.3993 19.9431 21.2663 19.9431 21.1021V19.8835C19.9431 19.5209 19.8815 19.1643 19.76 18.8239C19.7121 18.6897 19.7662 18.5403 19.889 18.468C20.454 18.1349 20.805 17.5213 20.805 16.8668C20.805 15.8426 19.9717 15.0092 18.9474 15.0092C18.178 15.0092 17.4991 15.4719 17.2179 16.188C17.1578 16.3407 16.9853 16.4158 16.8325 16.356C16.6798 16.2959 16.6046 16.1234 16.6646 15.9707C17.0358 15.0256 17.9318 14.4148 18.9474 14.4148C20.2994 14.4148 21.3994 15.5148 21.3994 16.8668C21.3994 17.6503 21.0187 18.3891 20.3919 18.8477C20.4886 19.1839 20.5375 19.5313 20.5375 19.8835V21.1021C20.5375 21.2663 20.4045 21.3993 20.2403 21.3993Z"
      fill="black"
    />
    <path
      d="M16.9413 21.3993C16.7771 21.3993 16.644 21.2663 16.644 21.1021C16.644 19.6272 15.4441 18.4272 13.9691 18.4272H7.54036C6.0654 18.4272 4.86543 19.6272 4.86543 21.1021C4.86543 21.2663 4.73233 21.3993 4.56821 21.3993C4.40409 21.3993 4.271 21.2663 4.271 21.1021C4.271 19.2994 5.73763 17.8328 7.54036 17.8328H13.9691C15.7718 17.8328 17.2385 19.2994 17.2385 21.1021C17.2385 21.2663 17.1054 21.3993 16.9413 21.3993Z"
      fill="black"
    />
    <path
      d="M16.9413 21.3994C16.7771 21.3994 16.644 21.2663 16.644 21.1022V14.1949C16.644 12.3003 14.9754 10.6997 13.0002 10.6997H8.72922C6.70703 10.6997 4.86543 12.3656 4.86543 14.1949V21.1022C4.86543 21.2663 4.73233 21.3994 4.56821 21.3994C4.40409 21.3994 4.271 21.2663 4.271 21.1022V14.1949C4.271 13.1154 4.76669 12.0838 5.66672 11.2903C6.52097 10.5372 7.63719 10.1052 8.72922 10.1052H13.0002C15.2975 10.1052 17.2385 11.978 17.2385 14.1949V21.1022C17.2385 21.2663 17.1054 21.3994 16.9413 21.3994Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6229 18.3423C10.6807 18.3989 10.7558 18.4273 10.8309 18.4273C10.9061 18.4273 10.9811 18.3989 11.0389 18.3423L13.741 15.6945C13.7977 15.6389 13.8298 15.5631 13.8302 15.4837C13.8306 15.4044 13.7993 15.3281 13.7431 15.2721L11.0411 12.57C10.925 12.4539 10.7369 12.4539 10.6207 12.57L7.91857 15.2721C7.86252 15.3282 7.83119 15.4044 7.83155 15.4837C7.83191 15.5631 7.864 15.6389 7.92071 15.6945L10.6229 18.3423ZM10.8309 17.714L8.55128 15.4801L10.8309 13.2005L13.1106 15.4801L10.8309 17.714Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8855 27.3438H24.6688C24.8329 27.3438 24.966 27.2107 24.966 27.0466V11.5914C24.966 11.4273 24.8329 11.2942 24.6688 11.2942H22.8855C22.7213 11.2942 22.5883 11.4273 22.5883 11.5914V27.0466C22.5883 27.2107 22.7213 27.3438 22.8855 27.3438ZM24.3715 26.7494H23.1827V11.8886H24.3715V26.7494Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.641 11.8885H27.6422C27.8063 11.8885 27.9394 11.7554 27.9393 11.5914C27.9393 11.5548 27.9328 11.5197 27.9207 11.4874L26.1445 4.97442C26.1091 4.84507 25.9917 4.75537 25.8577 4.75537H21.1023C20.9682 4.75537 20.8508 4.84507 20.8155 4.97436L19.0322 11.5131C19.0079 11.6025 19.0265 11.6981 19.0828 11.7717C19.139 11.8453 19.2263 11.8885 19.319 11.8885H27.641ZM27.2519 11.2941H19.7081L21.3293 5.3498H25.6307L27.2519 11.2941Z"
      fill="black"
    />
    <path
      d="M26.4521 14.8608C26.2879 14.8608 26.1549 14.7277 26.1549 14.5636V11.5914C26.1549 11.4273 26.2879 11.2942 26.4521 11.2942C26.6163 11.2942 26.7493 11.4273 26.7493 11.5914V14.5636C26.7493 14.7277 26.6163 14.8608 26.4521 14.8608Z"
      fill="black"
    />
    <path
      d="M26.4521 8.91645H23.48C23.3158 8.91645 23.1827 8.78336 23.1827 8.61924C23.1827 8.45511 23.3158 8.32202 23.48 8.32202H26.4521C26.6163 8.32202 26.7493 8.45511 26.7493 8.61924C26.7493 8.78336 26.6163 8.91645 26.4521 8.91645Z"
      fill="black"
    />
    <path
      d="M27.0465 27.3437H0.297215C0.133093 27.3437 0 27.2107 0 27.0465C0 26.8823 0.133093 26.7493 0.297215 26.7493H27.0465C27.2107 26.7493 27.3438 26.8823 27.3438 27.0465C27.3438 27.2107 27.2107 27.3437 27.0465 27.3437Z"
      fill="black"
    />
    <path
      d="M7.86591 7.18634C7.858 7.18634 7.8501 7.18604 7.84213 7.18539C7.76355 7.17909 7.69073 7.14182 7.63961 7.08178C7.29211 6.67364 6.94383 6.30789 6.60703 5.95427C5.56142 4.85629 4.65837 3.90806 4.76376 2.59497C4.84609 1.5691 5.74796 0.801098 6.77358 0.883783C7.53481 0.94489 7.95787 1.31468 8.26804 1.87898C8.66429 1.37133 9.14072 1.0737 9.90207 1.13487C10.3996 1.17482 10.8518 1.40587 11.1753 1.78553C11.4987 2.16513 11.6549 2.64787 11.615 3.14481C11.5852 3.51693 11.4801 3.85997 11.2939 4.19357C11.2138 4.33694 11.0327 4.38818 10.8895 4.30817C10.7462 4.22816 10.6949 4.0471 10.7749 3.90378C10.9187 3.64627 10.9997 3.38247 11.0226 3.09726C11.0497 2.75867 10.9433 2.42977 10.7229 2.17102C10.5023 1.91215 10.1939 1.75462 9.85457 1.72734C9.25289 1.67913 8.91769 1.89901 8.47425 2.63248C8.41605 2.72883 8.30804 2.78417 8.19611 2.77496C8.08389 2.76598 7.9864 2.69436 7.94425 2.58991C7.6235 1.7951 7.32753 1.52452 6.72603 1.47619C6.02609 1.4199 5.41234 1.94329 5.35629 2.6424C5.27218 3.6905 6.05117 4.50855 7.03751 5.54417C7.31832 5.83901 7.60697 6.14211 7.89932 6.47368C8.24147 6.19245 8.57542 5.93893 8.90022 5.69224L8.98879 5.62501C9.1195 5.52574 9.30597 5.55112 9.40524 5.68184C9.50457 5.81255 9.47913 5.99897 9.34841 6.0983L9.25967 6.1657C8.8702 6.46143 8.46741 6.76721 8.05862 7.1153C8.00465 7.16131 7.93623 7.18634 7.86591 7.18634Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.024 8.32112C11.032 8.32177 11.0399 8.32207 11.0478 8.32207C11.1181 8.32207 11.1865 8.2971 11.2404 8.2511C11.5566 7.98194 11.8688 7.74488 12.1708 7.51561L12.1708 7.5156C13.1214 6.79379 13.9425 6.17041 14.0267 5.12107C14.0936 4.28756 13.4693 3.55498 12.635 3.48805C12.5822 3.48383 12.53 3.48163 12.48 3.48163C11.9703 3.48163 11.631 3.70793 11.3697 4.01501C11.1235 3.60575 10.786 3.33968 10.2045 3.29302C10.1637 3.28975 10.1225 3.28809 10.0818 3.28809C9.29765 3.28809 8.63426 3.90094 8.57149 4.68327C8.48726 5.73303 9.19832 6.4797 10.0217 7.34423L10.0238 7.34643C10.2842 7.6199 10.5534 7.90265 10.8215 8.21751C10.8726 8.27755 10.9454 8.31482 11.024 8.32112ZM9.16402 4.73088C9.20218 4.25522 9.60532 3.88257 10.0818 3.88257C10.1066 3.88257 10.1316 3.88359 10.1568 3.88561C10.5986 3.92109 10.8066 4.11363 11.0471 4.7096C11.0893 4.81405 11.1867 4.88567 11.299 4.89465C11.4113 4.90363 11.5189 4.84852 11.5771 4.75217C11.8826 4.24678 12.1104 4.07618 12.4799 4.07618C12.5142 4.07618 12.5503 4.07773 12.5875 4.0807C13.095 4.12142 13.4748 4.56688 13.4342 5.07363C13.3712 5.85786 12.7169 6.35469 11.8113 7.04226L11.806 7.04628C11.5703 7.22524 11.3286 7.40887 11.081 7.61054C10.8676 7.37063 10.6572 7.14968 10.4521 6.93438L10.452 6.93427C9.66769 6.11068 9.10101 5.51562 9.16402 4.73088Z"
      fill="black"
    />
    <circle cx="24.6688" cy="2.08051" r="0.594429" fill="black" />
    <circle cx="12.7802" cy="0.891549" r="0.594429" fill="black" />
    <circle cx="2.08051" cy="2.675" r="0.594429" fill="black" />
    <circle cx="5.64704" cy="8.02485" r="0.594429" fill="black" />
    <circle cx="1.48603" cy="12.7802" r="0.594429" fill="black" />
    <circle cx="27.0466" cy="18.7246" r="0.594429" fill="black" />
    <circle cx="16.3467" cy="8.02485" r="0.594429" fill="black" />
    <path
      d="M18.7245 4.75538C18.6485 4.75538 18.5724 4.72637 18.5144 4.66835L17.4742 3.6281C17.3581 3.51201 17.3581 3.32381 17.4742 3.20778L18.3042 2.37766L17.4742 1.5476C17.3581 1.4315 17.3581 1.24331 17.4742 1.12728L18.5144 0.0870245C18.6304 -0.0290082 18.8187 -0.0290082 18.9347 0.0870245C19.0508 0.203117 19.0508 0.391313 18.9347 0.507345L18.1046 1.33741L18.9347 2.16747C19.0508 2.28356 19.0508 2.47176 18.9347 2.58779L18.1046 3.41791L18.9347 4.24797C19.0508 4.36406 19.0508 4.55226 18.9347 4.66829C18.8767 4.72637 18.8006 4.75538 18.7245 4.75538Z"
      fill="black"
    />
    <path
      d="M2.67518 10.1054C2.65129 10.1054 2.62709 10.1026 2.6029 10.0965L1.01773 9.70027C0.913828 9.6743 0.831976 9.59447 0.803384 9.49127C0.774791 9.38808 0.803918 9.27746 0.879649 9.20173L1.67583 8.40555L0.238973 8.1182C0.0780014 8.08598 -0.0263804 7.92941 0.00583768 7.7685C0.0380557 7.60753 0.19451 7.5032 0.35554 7.53536L2.33695 7.93167C2.44442 7.95319 2.53139 8.03207 2.56313 8.13698C2.59487 8.2419 2.56634 8.35573 2.48883 8.43325L1.67119 9.25089L2.74705 9.5198C2.9063 9.55957 3.00313 9.72096 2.9633 9.88021C2.92954 10.0153 2.80833 10.1054 2.67518 10.1054Z"
      fill="black"
    />
  </svg>
);

export default IconCouch;
