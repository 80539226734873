const IconExperiment = () => (
  <svg
    height="32px"
    width="32px"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    x="0px"
    y="0px"
  >
    <title>8-experiment</title>
    <g data-name="8-experiment">
      <path d="M45,12H35a.99974.99974,0,0,0-1,1v4a.99974.99974,0,0,0,1,1V43a5,5,0,0,0,10,0V18a.99974.99974,0,0,0,1-1V13A.99974.99974,0,0,0,45,12Zm-9,2h8v2H36Zm4,32a3.00328,3.00328,0,0,1-3-3V34h6v9A3.00328,3.00328,0,0,1,40,46Zm3-26H42a1,1,0,0,0,0,2h1v2H42a1,1,0,0,0,0,2h1v2H42a1,1,0,0,0,0,2h1v2H37V18h6Z" />
      <path d="M45,0a3,3,0,1,0,3,3A3.00328,3.00328,0,0,0,45,0Zm0,4a1,1,0,1,1,1-1A1.001,1.001,0,0,1,45,4Z" />
      <path d="M31,5a3,3,0,1,0,3,3A3.00328,3.00328,0,0,0,31,5Zm0,4a1,1,0,1,1,1-1A1.001,1.001,0,0,1,31,9Z" />
      <path d="M6,4H7V5A1,1,0,0,0,9,5V4h1a1,1,0,0,0,0-2H9V1A1,1,0,0,0,7,1V2H6A1,1,0,0,0,6,4Z" />
      <path d="M2,14a1,1,0,0,0,2,0V13H5a1,1,0,0,0,0-2H4V10a1,1,0,0,0-2,0v1H1a1,1,0,0,0,0,2H2Z" />
      <path d="M22,18.86182V12a1,1,0,0,0,1-1V7a1,1,0,0,0-1-1H12a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1v6.86182A14.99836,14.99836,0,1,0,32,33,15.09642,15.09642,0,0,0,22,18.86182ZM13,8h8v2H13Zm4,38A13.01442,13.01442,0,0,1,4,33a1.62524,1.62524,0,0,1,1.55518.669A3.53527,3.53527,0,0,0,8.59814,35a5.22452,5.22452,0,0,0,3.37891-1.18652,3.42854,3.42854,0,0,1,4.43457,0,5.41429,5.41429,0,0,0,6.76416.00048A3.26682,3.26682,0,0,1,25.39648,33a1.63011,1.63011,0,0,1,1.5586.66943,3.51344,3.51344,0,0,0,2.8761,1.3216A13.00849,13.00849,0,0,1,17,46ZM28.44141,32.33057A3.53958,3.53958,0,0,0,25.39648,31a5.23721,5.23721,0,0,0-3.38281,1.186,3.43509,3.43509,0,0,1-4.43994.00048,5.40718,5.40718,0,0,0-6.75977,0A3.25766,3.25766,0,0,1,8.59814,33a1.62657,1.62657,0,0,1-1.55566-.669A3.51034,3.51034,0,0,0,4.1604,31.00854,13.0879,13.0879,0,0,1,13.28564,20.5459,1.00023,1.00023,0,0,0,14,19.5874V12h6v7.5874a1.00023,1.00023,0,0,0,.71436.9585A13.075,13.075,0,0,1,30,33,1.63011,1.63011,0,0,1,28.44141,32.33057Z" />
      <path d="M22,37a3,3,0,1,0,3,3A3.00328,3.00328,0,0,0,22,37Zm0,4a1,1,0,1,1,1-1A1.0013,1.0013,0,0,1,22,41Z" />
      <path d="M15,38H14V37a1,1,0,0,0-2,0v1H11a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0V40h1a1,1,0,0,0,0-2Z" />
    </g>
  </svg>
);

export default IconExperiment;
