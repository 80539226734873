import PropTypes from 'prop-types';

import CloseButton from '../../CloseButton/CloseButton';
import BackButton from '../../BackButton';

import styles from './SpottyCatHeader.module.scss';

const SpottyCatHeader = ({
  handleBackButton,
  handleCloseButton,
  showBackButton
}) => (
  <div className={styles.spottyCatHeader}>
    <div className={styles.spottyCatHeader__interactions}>
      {showBackButton ? (
        <BackButton
          className={styles.spottyCatHeader__button}
          onClick={handleBackButton}
        />
      ) : (
        <div
          className={styles.spottyCatHeader__button}
          style={{ width: '9px' }}
        />
      )}
      <CloseButton
        className={styles.spottyCatHeader__button}
        onClick={handleCloseButton}
      />
    </div>
  </div>
);

SpottyCatHeader.propTypes = {
  handleBackButton: PropTypes.func.isRequired,
  handleCloseButton: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired
};

export default SpottyCatHeader;
