const IconWhatsapp = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9986 14.9247C19.9986 15.0342 19.9952 15.2716 19.9887 15.4551C19.9726 15.904 19.937 16.4831 19.8832 16.7471C19.8024 17.1437 19.6802 17.518 19.5209 17.8296C19.3326 18.1981 19.0922 18.5282 18.8069 18.8131C18.522 19.0973 18.1922 19.3367 17.8241 19.5243C17.5108 19.6839 17.1341 19.8062 16.7355 19.8867C16.4742 19.9394 15.8993 19.9743 15.4534 19.9901C15.2698 19.9966 15.0322 20 14.9233 20L5.07534 19.9983C4.96576 19.9983 4.72844 19.9949 4.54489 19.9884C4.09595 19.9723 3.51688 19.9366 3.25286 19.8829C2.85631 19.8021 2.48202 19.6798 2.1704 19.5206C1.80193 19.3322 1.47182 19.0918 1.18691 18.8066C0.902678 18.5217 0.663311 18.1919 0.475652 17.8238C0.316074 17.5104 0.193822 17.1338 0.113348 16.7352C0.0606123 16.4739 0.0256832 15.8989 0.00993083 15.4531C0.00342442 15.2695 0 15.0318 0 14.923L0.00171221 5.07534C0.00171221 4.96576 0.00513663 4.72844 0.011643 4.54489C0.0277378 4.09595 0.0633518 3.51688 0.117115 3.25286C0.197932 2.85631 0.320184 2.48202 0.479077 2.1704C0.66742 1.80193 0.907815 1.47182 1.19307 1.18691C1.47798 0.902678 1.80775 0.663311 2.17588 0.475652C2.48921 0.316074 2.8659 0.193822 3.2645 0.113348C3.52579 0.0606123 4.10075 0.0256832 4.54661 0.00993083C4.73016 0.00342442 4.96781 0 5.07671 0L14.9247 0.00171221C15.0342 0.00171221 15.2716 0.00513663 15.4551 0.011643C15.904 0.0277378 16.4831 0.0633518 16.7471 0.117115C17.1437 0.197932 17.518 0.320184 17.8296 0.479419C18.1981 0.667763 18.5282 0.908157 18.8131 1.19341C19.0973 1.47832 19.3367 1.8081 19.5243 2.17622C19.6839 2.48956 19.8062 2.86624 19.8867 3.26484C19.9394 3.52613 19.9743 4.10109 19.9901 4.54695C19.9966 4.7305 20 4.96815 20 5.07705L19.9983 14.925L19.9986 14.9247Z"
      fill="#25D366"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2597 4.78753C13.9136 3.44037 12.1236 2.69829 10.2166 2.69727C6.28705 2.69727 3.08898 5.89431 3.08727 9.82383C3.08693 11.0799 3.41499 12.3062 4.03892 13.3869L3.02734 17.0802L6.80654 16.0892C7.8479 16.6569 9.02008 16.9562 10.2135 16.9566H10.2166C14.1458 16.9566 17.3442 13.7592 17.3459 9.82965C17.3466 7.92533 16.6059 6.1347 15.2597 4.78753V4.78753ZM10.2166 15.7529H10.2142C9.1509 15.7525 8.10816 15.4669 7.19829 14.9272L6.98187 14.7988L4.73921 15.3868L5.3378 13.201L5.19706 12.977C4.60395 12.034 4.29061 10.944 4.29096 9.82452C4.29233 6.5583 6.95036 3.90129 10.219 3.90129C11.8017 3.90198 13.2893 4.51872 14.4081 5.6385C15.5268 6.75829 16.1425 8.24654 16.1419 9.82931C16.1405 13.0955 13.4824 15.7529 10.2162 15.7529H10.2166ZM13.4667 11.3165C13.2886 11.2275 12.4127 10.7967 12.2493 10.7371C12.086 10.6775 11.9671 10.6481 11.8487 10.8262C11.7298 11.0046 11.3884 11.4056 11.2847 11.5244C11.1809 11.6432 11.0768 11.658 10.8987 11.5689C10.7207 11.4799 10.1467 11.2919 9.46629 10.6851C8.93687 10.2128 8.57936 9.62967 8.47526 9.45126C8.3715 9.27284 8.4643 9.17662 8.55334 9.08792C8.63347 9.00814 8.7314 8.88006 8.82044 8.77596C8.90947 8.67186 8.93927 8.59755 8.99851 8.47872C9.05775 8.35989 9.0283 8.25579 8.98379 8.16675C8.93927 8.07772 8.58313 7.20107 8.43451 6.84458C8.29 6.49735 8.14309 6.54426 8.03385 6.53878C7.93009 6.53365 7.81126 6.53262 7.69244 6.53262C7.57361 6.53262 7.38081 6.57714 7.21747 6.75555C7.05412 6.93396 6.59388 7.36475 6.59388 8.24106C6.59388 9.11737 7.23219 9.96423 7.32123 10.0831C7.41026 10.2019 8.57731 12.0007 10.3642 12.7719C10.7891 12.9555 11.121 13.065 11.3795 13.1469C11.8062 13.2825 12.1945 13.2633 12.5017 13.2174C12.8438 13.1664 13.5557 12.7866 13.704 12.3706C13.8523 11.9545 13.8523 11.598 13.8078 11.5237C13.7633 11.4494 13.6444 11.4049 13.4664 11.3159L13.4667 11.3165Z"
      fill="white"
    />
  </svg>
);

export default IconWhatsapp;
