const IconConfetti = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.4191 44.5967L0 95.8543L55.4189 78.2416L27.4191 44.5967Z"
      fill="#0A0777"
    />
    <path
      d="M45.5162 57.9844C40.7098 52.178 41.484 43.5975 47.2903 38.7588L56.0322 49.2426L64.774 59.7263C58.9354 64.565 50.3226 63.7908 45.5162 57.9844Z"
      fill="#C9E3F7"
    />
    <path
      d="M55.9994 49.2738C49.9672 42.0158 50.9349 31.2417 58.1929 25.1772L69.1283 38.3384L80.0637 51.4996C72.838 57.4995 62.0639 56.5318 55.9994 49.2738Z"
      fill="#C9E3F7"
    />
    <path
      d="M69.1609 38.3384C61.419 29.0482 62.7094 15.2419 71.9996 7.5L85.9995 24.3386L99.9994 41.1771C90.6769 48.8867 76.9028 47.6287 69.1609 38.3384Z"
      fill="#C9E3F7"
    />
    <path
      d="M34.8062 34.5957C37.3181 34.5957 39.3545 32.5594 39.3545 30.0474C39.3545 27.5354 37.3181 25.499 34.8062 25.499C32.2942 25.499 30.2578 27.5354 30.2578 30.0474C30.2578 32.5594 32.2942 34.5957 34.8062 34.5957Z"
      fill="#0A0777"
    />
    <path
      d="M94.2212 67.0806C96.7331 67.0806 98.7695 65.0442 98.7695 62.5322C98.7695 60.0203 96.7331 57.9839 94.2212 57.9839C91.7092 57.9839 89.6729 60.0203 89.6729 62.5322C89.6729 65.0442 91.7092 67.0806 94.2212 67.0806Z"
      fill="#FFCCEF"
    />
  </svg>
);

export default IconConfetti;
