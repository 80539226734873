const IconFriends = () => (
  <svg
    height="32px"
    width="32px"
    fill="#000000"
    data-name="Layer 1"
    viewBox="0 0 128 128"
    x="0px"
    y="0px"
  >
    <path d="M91,55.76c8.12,0,14-10.09,14-16.89C105,31.77,98.72,26,91,26S77,31.77,77,38.87C77,45.67,82.88,55.76,91,55.76ZM91,30c5.51,0,10,4,10,8.91S96.43,51.79,91,51.79,81,43.86,81,38.87,85.49,30,91,30Z" />
    <path d="M41,55.76c8.12,0,14-10.09,14-16.89C55,31.77,48.72,26,41,26S27,31.77,27,38.87C27,45.67,32.88,55.76,41,55.76ZM41,30c5.51,0,10,4,10,8.91S46.42,51.79,41,51.79,31,43.86,31,38.87,35.49,30,41,30Z" />
    <path d="M19.23,43.59a5.69,5.69,0,0,0-3-5.08,5.27,5.27,0,0,0-4.08-.35A5.53,5.53,0,0,0,8.86,41L1.13,58.37a10.56,10.56,0,0,0-.95,6.72,10.33,10.33,0,0,0,10.11,8.52h7.63V99.4a2,2,0,0,0,4,0V71.63a2,2,0,0,0-2-2H10.29a6.35,6.35,0,0,1-6.18-5.27,6.65,6.65,0,0,1,.63-4.31l7.73-17.33a1.53,1.53,0,0,1,.88-.8,1.26,1.26,0,0,1,1,.09,1.76,1.76,0,0,1,.88,1.57,1.92,1.92,0,0,1-.19.84l-.06.13L9.24,59.08a4,4,0,0,0,.32,3.84,3.94,3.94,0,0,0,3.29,1.78h7.82a2,2,0,0,0,1.93-1.45c1.13-1.75,4.29-3.21,6.62-3.94A18.58,18.58,0,0,0,36,63a16.69,16.69,0,0,0,4.71.68,17.56,17.56,0,0,0,11-4A12.25,12.25,0,0,1,55,61.44a15.37,15.37,0,0,0,9.44,3.14H66a15.28,15.28,0,0,0,9.42-3.12,13.72,13.72,0,0,1,4.23-1.79,17.35,17.35,0,0,0,22.5.15c1.74.57,4.37,1.76,5.48,3.86a2,2,0,0,0,1.77,1.07h8.08a6.44,6.44,0,0,1,6.38,5.09,6.07,6.07,0,0,1-.6,4l-6.92,13.05a1.53,1.53,0,0,1-2,.68,1.62,1.62,0,0,1-.88-1.45,1.69,1.69,0,0,1,.2-.8L118.52,75a3.85,3.85,0,0,0-.24-3.74,4,4,0,0,0-3.39-1.84h-2.71a4,4,0,0,0-4,3.93V98.8a2,2,0,0,0,4,0V90.92l.37.2a5.52,5.52,0,0,0,7.37-2.38l6.92-13.05a10,10,0,0,0,1-6.61,10.43,10.43,0,0,0-10.3-8.3h-7c-2.21-3.07-6.07-4.49-8.31-5.09a2,2,0,0,0-1.85.44A13.47,13.47,0,0,1,81.55,56a2,2,0,0,0-1.76-.47c-.46.1-4.53,1-6.82,2.79A11.25,11.25,0,0,1,66,60.61h-1.6a11.34,11.34,0,0,1-7-2.29,17.31,17.31,0,0,0-5.59-2.77,2.06,2.06,0,0,0-1.92.42,13.68,13.68,0,0,1-9.24,3.75,12.51,12.51,0,0,1-3.58-.51,15,15,0,0,1-6-3.56,2,2,0,0,0-1.86-.48c-.76.19-7,1.82-9.76,5.56l-6.58,0,5.77-14.68A5.83,5.83,0,0,0,19.23,43.59Zm92.94,35.5V73.4l2.72-.07Z" />
    <path d="M70.91,102a2,2,0,0,0,2-2V77.83a8,8,0,0,0-15.91,0V100a2,2,0,0,0,4,0V77.83a4,4,0,0,1,7.91,0V100A2,2,0,0,0,70.91,102Z" />
    <path d="M102.91,79.84A2,2,0,0,0,101,78.45H94.79L92.89,73a2,2,0,0,0-3.78,0l-1.9,5.48H81a2,2,0,0,0-1.91,1.39,2,2,0,0,0,.78,2.22l4.93,3.36-1.87,5.39A2,2,0,0,0,83.61,93,2,2,0,0,0,86,93.1l5-3.44,5,3.44A2,2,0,0,0,98.39,93a2,2,0,0,0,.68-2.22l-1.87-5.39,4.93-3.36A2,2,0,0,0,102.91,79.84ZM93.69,83a2,2,0,0,0-.76,2.28l.39,1.14-1.19-.81a2,2,0,0,0-2.27,0l-1.19.81.39-1.14A2,2,0,0,0,88.31,83l-.87-.6h1.2a2,2,0,0,0,1.89-1.34L91,79.72l.47,1.35a2,2,0,0,0,1.89,1.34h1.2Z" />
    <path d="M50.91,79.84A2,2,0,0,0,49,78.45H42.79L40.89,73a2,2,0,0,0-3.78,0l-1.9,5.48H29a2,2,0,0,0-1.91,1.39,2,2,0,0,0,.78,2.22l4.93,3.36-1.87,5.39A2,2,0,0,0,31.61,93,2,2,0,0,0,34,93.1l5-3.44,5,3.44A2,2,0,0,0,46.39,93a2,2,0,0,0,.68-2.22L45.2,85.43l4.93-3.36A2,2,0,0,0,50.91,79.84ZM41.69,83a2,2,0,0,0-.76,2.28l.39,1.14-1.19-.81a2,2,0,0,0-2.27,0l-1.19.81.39-1.14A2,2,0,0,0,36.31,83l-.87-.6h1.2a2,2,0,0,0,1.89-1.34L39,79.72l.47,1.35a2,2,0,0,0,1.89,1.34h1.2Z" />
  </svg>
);

export default IconFriends;
