const IconHeartLock = () => (
  <svg
    height="32px"
    width="32px"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    enableBackground="new 0 0 64 64"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path d="M45,19.101V14c0-7.168-5.832-13-13-13S19,6.832,19,14v5.101c-6.843,0.822-12,6.965-12,14.667    c0,13.526,23.469,28.45,24.468,29.079C31.63,62.949,31.815,63,32,63s0.37-0.051,0.532-0.153C33.531,62.218,57,47.294,57,33.768    C57,26.065,51.843,19.923,45,19.101z M32,3c6.065,0,11,4.935,11,11v5.025c-4.076,0.135-7.933,2.131-11,5.718    c-3.067-3.587-6.924-5.583-11-5.718V14C21,7.935,25.935,3,32,3z M32,60.808c-3.499-2.306-23-15.671-23-27.04    C9,26.489,13.973,21,20.567,21c3.958,0,7.735,2.11,10.636,5.941c0.377,0.5,1.217,0.5,1.594,0C35.697,23.11,39.475,21,43.433,21    C50.027,21,55,26.489,55,33.768C55,45.137,35.499,58.502,32,60.808z" />
        <path d="M21.5,25c-4.687,0-8.5,3.813-8.5,8.5c0,0.553,0.447,1,1,1s1-0.447,1-1c0-3.584,2.916-6.5,6.5-6.5c0.553,0,1-0.447,1-1    S22.053,25,21.5,25z" />
        <path d="M32,34c-2.757,0-5,2.243-5,5c0,1.563,0.754,3.04,2,3.979V48c0,0.553,0.447,1,1,1h4c0.553,0,1-0.447,1-1v-5.021    c1.246-0.939,2-2.416,2-3.979C37,36.243,34.757,34,32,34z M33.497,41.581C33.189,41.761,33,42.09,33,42.445V47h-2v-4.555    c0-0.355-0.189-0.685-0.497-0.864C29.562,41.033,29,40.068,29,39c0-1.654,1.346-3,3-3s3,1.346,3,3    C35,40.068,34.438,41.033,33.497,41.581z" />
      </g>
    </g>
  </svg>
);

export default IconHeartLock;
