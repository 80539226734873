import { ARROW_UP, ARROW_DOWN } from './constants';

const handlers = {
  [ARROW_UP]: currentState => {
    let newState;

    if (currentState.selectedIndex > 0) {
      newState = {
        selectedIndex: currentState.selectedIndex - 1
      };

      if (currentState.filtered[newState.selectedIndex]) {
        newState.value = currentState.filtered[newState.selectedIndex];
      }
    }

    return newState;
  },
  [ARROW_DOWN]: currentState => {
    let newState;

    if (currentState.selectedIndex < currentState.filtered.length - 1) {
      newState = {
        selectedIndex: currentState.selectedIndex + 1
      };

      if (currentState.filtered[newState.selectedIndex]) {
        newState.value = currentState.filtered[newState.selectedIndex];
      }
    }

    return newState;
  }
};

export default handlers;
