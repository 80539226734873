import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styles from './Autocompleter.module.scss';

class AutocompleterItemMatch extends PureComponent {
  static propTypes = {
    keyword: PropTypes.string.isRequired
  };

  render() {
    return (
      <span className={styles['autocompleter__item-match']}>
        {this.props.keyword}
      </span>
    );
  }
}

export default AutocompleterItemMatch;
