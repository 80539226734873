const IconSparkles = () => (
  <svg
    height="32px"
    width="32px"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    version="1.1"
    viewBox="0 0 437 439"
    x="0px"
    y="0px"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <defs>
      <style type="text/css" />
    </defs>
    <g>
      <path
        className="fil0"
        d="M147 144c44,129 19,103 148,147 -129,44 -104,19 -148,148 -44,-129 -19,-104 -147,-148 128,-44 103,-18 147,-147z"
      />
      <path
        className="fil0"
        d="M339 74c29,85 13,69 98,98 -85,29 -69,12 -98,97 -29,-85 -12,-68 -97,-97 85,-29 68,-13 97,-98z"
      />
      <path
        className="fil0"
        d="M213 0c19,55 8,44 63,63 -55,19 -44,8 -63,63 -19,-55 -8,-44 -63,-63 55,-19 44,-8 63,-63z"
      />
    </g>
  </svg>
);

export default IconSparkles;
