import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';

import FormRadioButton from '../../FormRadioButton';

import IconFriends from '../icons/IconFriends';
import IconBackpacker from '../icons/IconBackpacker';
import IconLovers from '../icons/IconLovers';
import IconYoga from '../icons/IconYoga';

import styles from '../SpottyCat.module.scss';

const PropertyType = ({ onPropertyTypeSelected, propertyType }) => (
  <>
    <p className={styles['spotty-cat__title']}>
      {trans('spotty-cat-screen-title-property-type')}
    </p>
    <div className={styles['spotty-cat__options']}>
      <FormRadioButton
        value="explorer"
        customIcon={() => <IconBackpacker />}
        selected={propertyType === 'explorer'}
        onClick={() => onPropertyTypeSelected('explorer')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-property-type-explorer-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-property-type-explorer-description')}
          </p>
        </div>
      </FormRadioButton>

      <FormRadioButton
        value="spiritual"
        customIcon={() => <IconYoga />}
        selected={propertyType === 'spiritual'}
        onClick={() => onPropertyTypeSelected('spiritual')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-property-type-spiritual-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-property-type-spiritual-description')}
          </p>
        </div>
      </FormRadioButton>

      <FormRadioButton
        value="lover"
        customIcon={() => <IconLovers />}
        selected={propertyType === 'lover'}
        onClick={() => onPropertyTypeSelected('lover')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-property-type-lover-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-property-type-lover-description')}
          </p>
        </div>
      </FormRadioButton>

      <FormRadioButton
        value="trueLove"
        customIcon={() => <IconFriends />}
        selected={propertyType === 'teamPlayer'}
        onClick={() => onPropertyTypeSelected('teamPlayer')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-property-type-team-player-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-property-type-team-player-description')}
          </p>
        </div>
      </FormRadioButton>
    </div>
  </>
);

PropertyType.propTypes = {
  onPropertyTypeSelected: PropTypes.func.isRequired,
  propertyType: PropTypes.string.isRequired
};

export default PropertyType;
